<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('CURRENCYADMIN.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('CURRENCYADMIN.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="12">
      <vs-card v-if="editMode" >
        <div id="editCurrencyCard" class="vs-con-loading__container">
          <h3 v-if="!newCurrency">{{$t('CURRENCYADMIN.EDIT.HEADER')}}</h3>
          <h3 v-if="newCurrency">{{$t('CURRENCYADMIN.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('CURRENCYADMIN.EDIT.LABEL.NAME')"  class="inputx" :placeholder="$t('CURRENCYADMIN.EDIT.PLACEHOLDER.NAME')"  v-model="selected.name" />
            <vs-input :label="$t('CURRENCYADMIN.EDIT.LABEL.KUERZEL')"  class="inputx ml-3" :placeholder="$t('CURRENCYADMIN.EDIT.PLACEHOLDER.KUERZEL')"  v-model="selected.kuerzel" />
            <vs-input :label="$t('CURRENCYADMIN.EDIT.LABEL.SYMBOL')"  class="inputx ml-3" :placeholder="$t('CURRENCYADMIN.EDIT.PLACEHOLDER.SYMBOL')"  v-model="selected.symbol" />
          </div>
          <SaveCancel class="mt-3" :SaveAllowed = !currencyDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddCurrency">{{$t('CURRENCYADMIN.ADD')}}</vs-button>
            </div> 
            <div id="currencyadmin_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="currencies"
                :noDataText="$t('CURRENCYADMIN.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('CURRENCYADMIN.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('CURRENCYADMIN.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="kuerzel">
                    {{$t('CURRENCYADMIN.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="symbol">
                    {{$t('CURRENCYADMIN.TABLE.COL3')}}
                  </vs-th>                                                      
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>      
                    <vs-td :data="tr.kuerzel">
                      {{tr.kuerzel}}
                    </vs-td> 
                    <vs-td :data="tr.symbol">
                      {{tr.symbol}}
                    </vs-td>                                                           
                    <vs-td>
                      <vs-button  @click="EditCurrency(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteCurrency(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import SaveCancel from './components/SaveCancel';
import helperMethods from '../helper/staticFuncHelper';

export default {
  name: "CurrencyAdmin",
  components: {
    SaveCancel
  },
    data: function (){
      return {
      currencies:[],
      selected:[],
      selectedOld:"",
      editMode:false,
      newCurrency:false
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.currencies.currencies.data != null)
      {
        data = this.$store.state.currencies.currencies.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.currencies.currencies.status) != 'undefined')
        loading = this.$store.state.currencies.currencies.status.loading;
      return loading;
    },
    currencyDataChanged(){
        return JSON.stringify(this.selected) != this.selectedOld;
    },
  },
  watch: {
      getTableData(value) {
          this.currencies = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#currencyadmin_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#currencyadmin_table'});
        }
      }
    },
  methods: {    
      ...helperMethods,
      LoadData: function ()
      {
          this.$store.dispatch('currencies/getCurrencies'); 
      },
      EditCurrency(id){
        if(this.$store.state.currencies.currencies.data != null)
        {
          for(var i = 0; i < this.$store.state.currencies.currencies.data.length; i++)
          {
            if(this.$store.state.currencies.currencies.data[i].id==id)
            {
              this.selectedOld = JSON.stringify(this.$store.state.currencies.currencies.data[i]);
              this.selected = JSON.parse(this.selectedOld);
              this.editMode = true;
              break;
            }
          }
        }
      },
      AddCurrency(){
        this.selected={id:0,name:"",kuerzel:"",symbol:""};
        this.selectedOld = JSON.stringify(this.selected);
        this.editMode = true;
        this.newCurrency = true;
      },
      Save(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editCurrencyCard'});

        var name = this.selected.name;
            this.$store.dispatch('currencies/saveCurrency', this.selected)
              .then(response => {
                if(response.success == true)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('CURRENCYADMIN.SUCCESS.SAVE').replace("*1*",name)});
                  this.editMode = false;
                }
                else
                {
                  var text = this.$t('CURRENCYADMIN.ERROR.SAVE').replace("*1*",name);
                  text = text.replace("*2*",this.$t(response.text));
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});
                }
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editCurrencyCard'});
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('CURRENCYADMIN.ERROR.SAVE').replace("*1*",name)+' '+error});
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editCurrencyCard'});
              });        
      },
      Cancel(){
        this.$data.selected = [];
        this.editMode = false;
        this.newCurrency = false;
      },    
      OpenConfirmDeleteCurrency(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('CURRENCYADMIN.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('currencies/deleteCurrency', parameters.id)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('CURRENCYADMIN.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            var text = this.$t('CURRENCYADMIN.WARNING.DELETE').replace("*1*",parameters.name);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('CURRENCYADMIN.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>