<template>
    <div>
        <div class=" default-input d-flex align-items-center">
          <vs-button :disabled="SaveAllowed" type="border" icon="done" @click="Save">{{$t('COM.SAVE')}}</vs-button>
          <vs-button class="ml-3"  type="border" icon="clear" @click="Cancel">{{$t('COM.CANCEL')}}</vs-button>
        </div>
    </div>
</template>
<script>
export default {
  name: "SaveCancel",
  props:['SaveAllowed'],
  methods:{
      Save(){
          this.$emit('SaveClicked');
      },
      Cancel(){
        this.$emit('CancelClicked');
      }
  }
};
</script>